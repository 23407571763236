import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import styles from './Articles.module.css';
import { fetchArticles } from '../../redux/slices/articlesSlice';
import ArticleCard from './ArticleCard';
import ArticlesScelet from './ArticlesScelet';

const ArticlesDesc = () => {
  const dispatch = useDispatch();
  const { articles } = useSelector((state) => state.articlesSlice);

  // const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    // // Запрос к PHP-скрипту для получения статей
    // axios
    //   .get('https://ac-go.ru/getArticles.php')
    //   .then((response) => {
    //     setArticles(response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Ошибка при получении данных: ', error);
    //   });
    dispatch(fetchArticles());
  }, []);

  console.log(articles);

  const articleCards = articles.items.map((article) => (
    <ArticleCard key={article.id} {...article} />
  ));

  const skeleton = [...new Array(6)].map((_, index) => (
    <ArticlesScelet key={index} />
  ));

  return (
    <div>
      <div className="container">
        <div className="wrapper__title">
          <h4 className="headline-4">Стати об автокондиционере</h4>
        </div>
        <div className={styles.articlesList}>
          {articles.status === 'loading' ? skeleton : articleCards}
        </div>
      </div>
    </div>
  );
};

export default ArticlesDesc;
