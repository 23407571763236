import React from 'react';
import CardSwiper from './card/CardSwiper';
import { useSelector } from 'react-redux';
const Bestseller = () => {
  const { items } = useSelector((state) => state.productSlice);
  const { orders } = useSelector((state) => state.productSlice);
  console.log(orders);

  return (
    <div className="bestseller">
      <div className="container">
        <div className="wrapper__title">
          <h2 className="headline-4">Best Seller</h2>
        </div>
        <div className="bestseller__cards">
          {/* @@loop('templates/card.html', './data/bestsellers.json') */}
          {items.map((el) => (
            <CardSwiper key={el.id} el={el} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bestseller;
