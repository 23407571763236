import React from 'react';
import Value from './Value';

const Values = () => {
  const valuesData = [
    {
      img: 'shipping.svg',
      title: 'Free Shipping',
      text: 'Order above $200',
    },
    {
      img: 'finance.svg',
      title: 'Money-back',
      text: '30 days guarantee',
    },
    {
      img: 'lock.svg',
      title: 'Secure Payments',
      text: 'Secured by Stripe',
    },
    {
      img: 'call.svg',
      title: '24/7 Support',
      text: 'Phone and Email support',
    },
  ];

  return (
    <section className="values">
      <div className="container">
        <div className="container-bg">
          <div className="values__grid">
            {valuesData.map((el) => (
              <Value key={el.text} {...el} />
            ))}
            {}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
