import { configureStore } from '@reduxjs/toolkit';
import productSlice from './slices/productSlice';
import articlesSlice from './slices/articlesSlice';

export const store = configureStore({
  reducer: {
    productSlice,
    articlesSlice,
  },
});
