import { IoCheckmarkCircle } from 'react-icons/io5';
const Services = () => {
  return (
    <div className="container">
      <div className="services">
        <h2>Услуги</h2>
        <div className="services-list">
          <div className="service-item">
            <span className="icon">✔</span> Выезд к вам
          </div>
          <div className="service-item">
            <span className="icon">✔</span> Заправка хладагентом
          </div>
          <div className="service-item">
            <span className="icon">✔</span> Добавление красителя
          </div>
          <div className="service-item">
            <span className="icon">✔</span> Оппрессовка системы при помощи азота
          </div>
          <div className="service-item">
            <span className="icon">✔</span> Замена ниппеля
          </div>
          <div className="service-item">
            <span className="icon">✔</span> Подключение к системе с фреоном
            R1234yf
          </div>
          <div className="service-item">
            <span className="icon">✔</span> Замена резиновых колечек
          </div>
          <div className="service-item">
            <span className="icon">✔</span> Диагностика автокондиционера
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
