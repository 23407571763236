import React from 'react';
import ContentLoader from 'react-content-loader';

const ArticlesScelet = (props) => (
  <ContentLoader
    speed={2}
    width={360}
    height={360}
    viewBox="0 0 360 360"
    backgroundColor="#e9e9e9"
    foregroundColor="#dfdfdf"
    {...props}
  >
    <rect x="2" y="16" rx="17" ry="17" width="342" height="342" />
    <rect x="288" y="285" rx="0" ry="0" width="0" height="1" />
  </ContentLoader>
);

export default ArticlesScelet;
