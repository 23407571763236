import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orders: [],
  items: [
    {
      id: 1,
      img: '01',
      title: 'Колпачки для портов R134a R12',
      price: 300,
      status: 'new',
      rating: 5,
    },
    {
      id: 2,
      img: '02',
      title: 'Beats Studio Pro',
      price: 349.99,
      status: 'new',
      rating: 4,
    },
    {
      id: 3,
      img: '03',
      title: 'Sony - WH-CH720N Wireless Noise Canceling',
      price: 149.99,
      status: 'new',
      rating: 3,
    },
    {
      id: 4,
      img: '04',
      title: 'Skullcandy - Rail True Wireless Earbuds',
      price: 79.99,
      status: 'new',
      rating: 2,
    },
    {
      id: 5,
      img: '05',
      title: 'Beats Studio Pro',
      price: 224.99,
      status: 'new',
      rating: 1,
    },
    {
      id: 6,
      img: '01',
      title: 'Skullcandy - Crusher anc 2 wireless headphones',
      price: 299.99,
      status: 'new',
      rating: 5,
    },
    {
      id: 7,
      img: '02',
      title: 'Beats Studio Pro',
      price: 349.99,
      status: 'new',
      rating: 4,
    },
    {
      id: 8,
      img: '03',
      title: 'Sony - WH-CH720N Wireless Noise Canceling',
      price: 149.99,
      status: 'new',
      rating: 3,
    },
    {
      id: 9,
      img: '04',
      title: 'Skullcandy - Rail True Wireless Earbuds',
      price: 79.99,
      status: 'new',
      rating: 2,
    },
    {
      id: 10,
      img: '05',
      title: 'Beats Studio Pro',
      price: 224.99,
      status: 'new',
      rating: 1,
    },
  ],
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setOrders(state, action) {
      state.orders = action.payload;
    },
    setItems(state, action) {
      state.items = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrders, setItems } = productSlice.actions;

export default productSlice.reducer;
