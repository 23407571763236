import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import styles from './Articles.module.css';

const ArticlesFull = () => {
  const [post, setPost] = React.useState([]);
  const { id } = useParams();

  React.useEffect(() => {
    // Запрос к PHP-скрипту для получения статей
    axios
      .get(`https://ac-go.ru/getArticles.php?id=${id}`)
      .then((response) => {
        setPost(response.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении данных: ', error);
      });
  }, []);

  console.log(post);

  return (
    <div>
      <div className="container">
        <div className="wrapper__title">
          <h3 className="headline-3">{post.title}</h3>
        </div>
        <div className={styles.articleContent}>{post.content}</div>
      </div>
    </div>
  );
};

export default ArticlesFull;
