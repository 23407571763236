import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainLayout from './layouts/MainLayout';
import Home from './components/Home';
import Shop from './components/menu/Shop';
import ArticlesDesc from './pages/articles/ArticlesDesc';
import ArticlesFull from './pages/articles/ArticlesFull';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="shop" element={<Shop />} />
            <Route path="articles" element={<ArticlesDesc />} />
            <Route path="articles/:id" element={<ArticlesFull />} />
            <Route />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
