import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { setOrders } from '../../redux/slices/productSlice';
import { useDispatch, useSelector } from 'react-redux';
const Order = (props) => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.productSlice);

  const deleteOrder = (order) => {
    dispatch(setOrders(orders.filter((p) => p.id !== order.id)));
  };

  return (
    <div className="item">
      <img src={`./img/products/arrivals/${props.item.img}.jpg`} />
      <h2>{props.item.title}</h2>
      <b>${props.item.price}</b>
      <FaTrash
        className="delete-icon"
        onClick={() => deleteOrder(props.item)}
      />
    </div>
  );
};

export default Order;
