import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchArticles = createAsyncThunk('posts/fetchPosts', async () => {
  const { data } = await axios.get('https://ac-go.ru/getArticles.php');
  return data;
});

const initialState = {
  articles: {
    items: [],
    status: 'loading',
  },
};

export const articlesSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    increment() {},
    decrement() {},
    incrementByAmount() {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchArticles.pending, (state) => {
        state.articles.items = [];
        state.articles.status = 'loading';
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.articles.items = action.payload;
        state.articles.status = 'loaded';
      })
      .addCase(fetchArticles.rejected, (state) => {
        state.articles.items = [];
        state.articles.status = 'error';
      });
  },
});

export const {} = articlesSlice.actions;

export default articlesSlice.reducer;
