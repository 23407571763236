import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Articles.module.css';

const ArticleCard = ({ id, imageUrl, title }) => {
  return (
    <>
      <Link className={styles.acrticlesCard} to={`${id}`}>
        <div className={styles.imgWrapper}>
          <img className={styles.articlesImg} src={imageUrl} alt="" />
        </div>

        <div className={styles.articlesListTitle}>{title}</div>
      </Link>
    </>
  );
};

export default ArticleCard;
