import React, { useState } from 'react';
import { carData } from './carData';
import styles from './SelectCar.module.css';

const CarDataComponent = () => {
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedCarData, setSelectedCarData] = useState(null);
  const [cost, setCost] = useState(0);

  const brands = Object.keys(carData);
  // console.log(brands);

  const handleBrandChange = (e) => {
    setSelectedBrand(e.target.value);
    setSelectedModel('');
    setSelectedCarData(null);
    setCost(0);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
    const car = carData[selectedBrand].find(
      (car) => car.model === e.target.value
    );
    setSelectedCarData(car);
    if (car) {
      calculateCost(car.freonMass);
    }
  };

  const extractMaxNumber = (str) => {
    const numbers = str.match(/\d+/g);
    const parsedNumbers = numbers ? numbers.map(Number) : [];
    return Math.max(...parsedNumbers);
  };

  const calculateCost = (mass) => {
    const maxMass = extractMaxNumber(mass);
    let calculatedCost = 2500;
    const additionalCost = (maxMass - 500) * 2;
    if (additionalCost > 0) {
      calculatedCost += additionalCost;
    }
    setCost(calculatedCost);
  };

  return (
    <section className="car-data__section">
      <div className="container">
        <div className="container-bg">
          <div className="car-data__title">
            <h2 className="headline-4">Рассчитать стоимость заправки</h2>
          </div>

          <div className="car-data">
            <div className="select-wrapper">
              <select value={selectedBrand} onChange={handleBrandChange}>
                <option disabled value="">
                  Выберите марку авто
                </option>
                {brands.map((brand, index) => (
                  <option key={index} value={brand}>
                    {brand}
                  </option>
                ))}
              </select>
            </div>

            {selectedBrand && (
              <div className="select-wrapper">
                <select value={selectedModel} onChange={handleModelChange}>
                  <option disabled value="">
                    Выберите модель
                  </option>
                  {carData[selectedBrand].map((car, index) => (
                    <option key={index} value={car.model}>
                      {car.model}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {selectedCarData && (
              <>
                <div class={styles.grid}>
                  <div class={styles.title}>Год выпуска:</div>
                  <div class={styles.title}>Марка фриона:</div>
                  <div class={styles.title}>Масса фреона:</div>
                  <div class={styles.title}>Тип масла:</div>
                  <div class={styles.title}>Объем масла в системе:</div>
                  <div class={styles.text}>{selectedCarData.year}</div>
                  <div class={styles.text}>{selectedCarData.freionBrand}</div>
                  <div class={styles.text}>{selectedCarData.freonMass} (г)</div>
                  <div class={styles.text}>{selectedCarData.oilType}</div>
                  <div class={styles.text}>{selectedCarData.oilVolume} (г)</div>
                </div>

                <p className={styles.p}>Стоимость: {cost} рублей</p>
                <span>Выезд включен в стоимость</span>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarDataComponent;
