import React from 'react';
// import { useContext } from '../../../App';
import { setOrders } from '../../../redux/slices/productSlice';
import { useDispatch, useSelector } from 'react-redux';
const Card = ({ el }) => {
  // const { addToOrder } = React.useContext(useContext);
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.productSlice);
  const stars = [];
  for (let i = 1; i < el.rating; i++) {
    stars.push(<img key={i} src="./img/icons/star.svg" alt="" />);
  }

  const addToOrder = (item) => {
    let isInArray = false;

    orders.forEach((el) => {
      if (el.id === item.id) isInArray = true;
    });

    if (!isInArray)
      //   setOrders((prevState) => ({
      //     ...prevState,
      //     orders: [...prevState.orders, item],
      //   }));

      // if (!isInArray)
      // console.log(item);

      dispatch(setOrders([...orders, item]));
  };

  return (
    <div className="swiper-slide">
      <article className="card">
        <div className="card__picture">
          <img
            src={`./img/products/arrivals/${el.img}.jpg`}
            // src={`./img/products/arrivals/${el.img}@2x.jpg`}
            // srcset={`./img/products/arrivals/${el.img}@2x.jpg 2x`}
            alt="Headphones"
          />
          <div className="card__new">{el.status}</div>
          <div className="card__fav">
            <button className="btn-fav">
              {/* <!-- <img src="./img/icons/fav.svg" alt="Fav"> --> */}
              <svg
                className="btn-fav__svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.577 5.76422C10.2546 6.07365 9.74548 6.07365 9.42304 5.76422L8.84604 5.2105C8.17068 4.56239 7.25832 4.16667 6.25004 4.16667C4.17897 4.16667 2.50004 5.8456 2.50004 7.91667C2.50004 9.90219 3.57486 11.5417 5.1265 12.8888C6.67947 14.237 8.53621 15.1312 9.64558 15.5876C9.87754 15.683 10.1225 15.683 10.3545 15.5876C11.4639 15.1312 13.3206 14.237 14.8736 12.8888C16.4252 11.5417 17.5 9.90218 17.5 7.91667C17.5 5.8456 15.8211 4.16667 13.75 4.16667C12.7418 4.16667 11.8294 4.56239 11.154 5.2105L10.577 5.76422ZM10 4.00798C9.0268 3.074 7.70545 2.5 6.25004 2.5C3.2585 2.5 0.833374 4.92512 0.833374 7.91667C0.833374 13.2235 6.64199 16.1542 9.01153 17.1289C9.64968 17.3914 10.3504 17.3914 10.9885 17.1289C13.3581 16.1542 19.1667 13.2235 19.1667 7.91667C19.1667 4.92512 16.7416 2.5 13.75 2.5C12.2946 2.5 10.9733 3.074 10 4.00798Z"
                />
              </svg>
            </button>
          </div>
          <div className="card__btn">
            <button
              className="btn btn--small btn--wide"
              onClick={() => {
                addToOrder(el);
                // console.log(el);
              }}
            >
              Add to cart
            </button>
          </div>
        </div>
        <div className="card__desc">
          <div className="card__rating">
            {stars}

            <img src={'img/icons/star.svg'} alt="Star" />
          </div>
          <div className="card__title">{el.title}</div>
          <div className="card__price">
            {el.price} {'руб'}
          </div>
        </div>
        <a href="#!" className="card__link"></a>
      </article>
    </div>
  );
};

export default Card;
